import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { LandPageEnQuery } from '../../../types/graphql-types'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { OpticalImage } from '../../components/OpticalImage'
import { PhilosophyBlock } from '../../components/PhilosophyBlock'
import { PhilosophyItem } from '../../components/PhilosophyItem'
import { PhilosophyRelatedList } from '../../components/PhilosophyRelatedList'
import { SectionList } from '../../components/SectionList'
import { Statement } from '../../components/Statement'

export const query = graphql`
  query LandPageEn {
    heroMobile: file(
      relativePath: { eq: "images/philosophy-land-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(
      relativePath: { eq: "images/philosophy-land-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageOsuzuyama: file(
      relativePath: { eq: "images/philosophy-osuzuyama.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWater: file(relativePath: { eq: "images/philosophy-water.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWood: file(relativePath: { eq: "images/philosophy-wood.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageIngredients: file(
      relativePath: { eq: "images/philosophy-ingredients.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritMobile: file(
      relativePath: { eq: "images/philosophy-spirit-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    spiritTablet: file(
      relativePath: { eq: "images/philosophy-spirit-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface LandPageProps extends AppPageProps {
  data: LandPageEnQuery
}

const LandPage: React.FC<LandPageProps> = ({
  pathContext: { langKey },
  data: {
    heroMobile,
    heroTablet,
    imageOsuzuyama,
    imageWater,
    imageWood,
    imageIngredients,
    spiritMobile,
    spiritTablet,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="Land"
    // eslint-disable-next-line max-len
    pageDescription="With Mankichi Mountain on the north and Mount Yahazu and Mount Kurodake on the south, the Osuzu Mountain massif is one of Miyazaki prefecture’s prominent mountain regions. Its main ridge is Osuzu Mountain, which reaches an altitude of 1405.2 meters. With an annual rainfall of 3,000 milliliters, this region is known for its ample waters that flow through approximately thirty different waterfalls, including Yatogi Waterfall."
    titleLabel="Land"
  >
    <LoadingContext.Provider value="eager">
      <OpticalImage
        fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
        fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
        sizes="100vw"
      />
    </LoadingContext.Provider>
    <Statement>
      <p>
        Rain falls on the ridges of <br />
        Osuzu Mountain quietly, like mist. <br />
        It soaks the forest, <br />
        saturating the soil, <br />
        permeating deeply into the earth. <br />
        Water resurfaces as a spring <br />
        to become the finest liquor <br />
        through nature’s law and <br />
        the craft of human hands.
      </p>
      <p>
        In the deep forest, <br />
        where the fresh water springs: <br />
        this is where our liquor is made. <br />
        Osuzuyama Distillery
      </p>
    </Statement>
    <SectionList>
      <BlockList>
        <PhilosophyBlock
          title="Osuzuyama Distillery"
          fluid={imageOsuzuyama?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            With Mankichi Mountain on the north and Mount Yahazu and Mount
            Kurodake on the south, the Osuzu Mountain massif is one of Miyazaki
            prefecture’s prominent mountain regions. Its main ridge is Osuzu
            Mountain, which reaches an altitude of 1405.2 meters. With an annual
            rainfall of 3,000 milliliters, this region is known for its ample
            waters that flow through approximately thirty different waterfalls,
            including Yatogi Waterfall. Following the universal rule in liquor
            making — everything begins with water — we arrived at Osuzu Mountain
            in search of pristine water. In 1998, Osuzuyama Distillery opened as
            a branch of Kuroki Honten. Surrounded by a forest of deciduous
            trees, such as beech, maple, and Japanese oak, the chirping of the
            birds and murmur of the pristine brook echo throughout our
            environment. Here, human craft and nature are united in our
            distillation process. This is the only place it can be made. This is
            the only place it will be made. This is the spirit of our craft at
            Osuzuyama Distillery.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="Water"
          fluid={imageWater?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            Osuzu Mountain experiences 3,000 milliliters of rain per year. As
            rain falls on the forests of Osuzu Mountain, water permeates the
            ground, picking up minerals as it filters through many layers of
            soil. Water that has been filtered and purified on Osuzu Mountain is
            soft and can be enjoyed as a smooth, delicious drink. At Osuzuyama
            Distillery, we draw water close to the source, in the upper streams,
            and use it for preparation and all of our distilled liquor-making
            processes.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="Wood"
          fluid={imageWood?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            One of the key features of wood is that it controls the humidity of
            the atmosphere. At Osuzuyama Distillery, we take advantage of this
            quality by using wood in our koji-making cellars and in the barrels
            used for moromi mash. Obi-sugi, a type of cedar tree from Miyazaki
            prefecture that has been used in ships since the Edo period, is
            known for its exceptional durability and pliability. Using obi-sugi
            for our deep barrels enables us to create an ideal and active
            environment for the microbes in the moromi.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="Ingredients"
          fluid={imageIngredients?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            All ingredients at Osuzuyama Distillery are sourced locally from the
            Kyushu region, including from our own farm, Yomigaeru Daichi no Kai.
            Growing and harvesting our own ingredients enables us to thoroughly
            analyze their qualities and extract the best parts. At Osuzuyama
            Distillery, liquor making is a craft in which humans and the earth
            collaborate.
          </p>
        </PhilosophyBlock>
      </BlockList>
      <PhilosophyRelatedList>
        <PhilosophyItem
          to="/philosophy/spirit/"
          fluidMobile={spiritMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={spiritTablet?.childImageSharp?.fluid as FluidObject}
          label="Spirit"
        />
      </PhilosophyRelatedList>
    </SectionList>
  </Layout>
)

export default LandPage
