import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'

const StyledStatement = styled.div`
  font-family: var(--font-family-serif);
  font-feature-settings: 'palt';
  font-size: var(--font-size-l1);
  letter-spacing: 0.1em;
  line-height: 2.5;
  margin-left: 6rem;
  margin-right: 2rem;
  padding: 4rem 0;
  white-space: nowrap;

  &:lang(en) {
    letter-spacing: 0.05em;
    line-height: 2;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1.5em;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.6rem;
    margin-left: auto;
    margin-right: auto;
    padding: 6rem 0;
    width: 18em;
  }
`

export const Statement: React.FC = ({ children }) => (
  <StyledStatement>{children}</StyledStatement>
)
